<script lang="ts" setup>
const { seasonInitData } = useSeasonsData({ immediate: false });

const valueProgress = computed(() => `${seasonInitData.value?.totalProgressPercents}%` || "0%");
const level = computed(() => seasonInitData.value?.currentLevel || 1);
</script>

<template>
	<div class="wrap">
		<div class="top-level">
			<AText class="" :modifiers="['semibold']">
				<i18n-t keypath="Level {key}">
					<template #key>
						{{ level }}
					</template>
				</i18n-t>
			</AText>
			<AText class="text-сirebon" :modifiers="['semibold']">
				<i18n-t keypath="{key} done">
					<template #key>
						{{ valueProgress }}
					</template>
				</i18n-t>
			</AText>
		</div>
		<div class="level-progress">
			<AText class="level text-tlalnepantla" variant="toledo" :modifiers="['center', 'bold']" as="div">
				{{ level }}
			</AText>
			<div class="progress-active" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.top-level {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.level-progress {
	position: relative;
	align-self: flex-end;
	width: calc(100% - 10px);
	height: 28px;
	background: var(--compton);
	border-radius: 3px;
	border-style: solid;
	border-width: 4px;
	border-image: url("/nuxt-img/season/progress-empty.png") 8 stretch;

	.level {
		width: 42px;
		height: 42px;
		line-height: 42px;
		color: var(--compton);
		background: url("/nuxt-img/season/number.png") no-repeat center center / cover;
		position: absolute;
		top: -12px;
		left: -16px;
		z-index: 10;
	}

	.progress-active {
		position: absolute;
		top: 2px;
		left: 10px;
		width: v-bind(valueProgress);
		max-width: calc(100% - 12px);
		height: 16px;
		background: var(--gapan) no-repeat center center / cover;
		z-index: 9;
	}
}
</style>
